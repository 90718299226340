import api from '@/api/api.js'
import { updateToken, imgDomain } from '@/api/request.js'
import { getUrlValue, deepFor } from '@/utils/utils.js'
import { Heji } from '@/model/heji/heji.js'
import { BuyCart } from '@/model/order/buyCart.js'
// import { UserMessage } from '@/model/message.js'
import moment from 'moment'
import moai from '@/model/moai/MoAI.js'
const baseHeadImage = require('@/assets/icon/defaultUserHeader.png')

export class User {
	constructor() {
		this.headImage = null
		this.loginStatus = false
		this.nickname = null
		this.tokenInfo = null
		this.heji = new Heji(this)
		this.buyCart = new BuyCart()
		// this.$message = new UserMessage()
	}
	
	static defaultHeadImage = baseHeadImage
	
	async login( params ) {
		let res = await api.loginSubmit(params).catch(err => { 
			throw err
		})
		res.data.client.grantType = params.grantType
		let { expiresIn } = res.data
		let autoLogin = JSON.parse(localStorage.getItem('autoLogin'))
		if (autoLogin) {
			res.data.expiresInDate = moment().add(expiresIn, 'seconds').format('YYYY/MM/DD HH:mm:ss')
		} else {
			res.data.expiresInDate = moment().add(72, 'hours').format('YYYY/MM/DD HH:mm:ss')
		}
		this.tokenInfo = res.data
		this.loginStatus = true
		updateToken(res.data.accessToken)
		this.setUserInfo(res.data.client)
		// this.updateInfo()
		moai.updateCount()
		moai.getConfig()
	}
	
	logout() {
		this.setUserInfo({
			loginStatus: false,
			nickname: null,
			tokenInfo: null,
			headImage: null
		})
		updateToken(null)
		localStorage.removeItem('userInfo')
		location.replace(location.origin)
		return 
	}
	
	async autoLogin() {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		if (userInfo) {
			console.log('auto login')
			let tokenInfo = userInfo.tokenInfo
			let { expiresInDate } = userInfo.tokenInfo
			if ( new Date() < new Date(expiresInDate) ) {
				this.loginStatus = true
				this.setUserInfo(userInfo)
				this.updateInfo()
				updateToken(tokenInfo.accessToken)
				moai.updateCount()
				moai.getConfig()
				this.refreshToken()
				return this
			} else {
				this.logout()
			}
		}
		let query = {}
		let queryStr = location.href.split('?')[1]
		if(queryStr){
			queryStr.split('&').map(item => item.split('=')).forEach(item =>{
			    query[item[0]] = item[1].indexOf('#') !== -1 ? item[1].split('#')[0] : item[1]
			})
		
		}
		let wxCode = query.code
		let state = query.state
		let redirect = query.redirect
		if (wxCode && state == 'weixin_state') {
			console.log('weChat login')
			await this.login({ grantType: 'wechat', code: wxCode })
			.catch(err => { 
				throw err 
			})
			let url = location.origin
			if (this.phone) {
				if (redirect) {
					url += '/#' + decodeURIComponent(redirect)
				} else {
					url += '/#/index'
				}
			} else {
				url += '/#/login?type=verifyPhone'
			}
			location.href = url
			return this
		}
		return this
	}
	
	setUserInfo(info) {
		let that = this
		let { grantType } = info
		deepFor(that, info, (object, key, value) => {
			switch(key) {
				case 'headImage':
					object[key] = value ? (value.indexOf('http') === -1 ? imgDomain + value : value) : baseHeadImage
				break;
				default: object[key] = value
			}
		})
		
		let autoLogin = JSON.parse(localStorage.getItem('autoLogin')) 
		var cache = []; // 声明cache变量，便于匹配是否有循环引用的情况
		var replacer = function(key, value) {
		    if (typeof value === 'object' && value !== null) {
		        if (cache.indexOf(value) !== -1) {
		            return; // 移除
		        }
		        cache.push(value);  // 收集所有的值
		    }
		    return value;
		}
		// https://blog.csdn.net/qq_27674439/article/details/90717791
		// JSON.stringify(value [, replacer] [, space]) 
		// replacer为方法时，那很简单，就是说把系列化后的每一个对象（记住是每一个）传进方法里面进行处理。
		localStorage.setItem('userInfo', JSON.stringify(this, replacer))
		cache = null;
	}
	
	
	
	async refreshToken () {
		if (!this.loginStatus) return 
		let res = await api.refreshToken(this.tokenInfo.refreshToken).catch(err => {
			throw err
		})
		let { expiresIn } = res.data
		let autoLogin = JSON.parse(localStorage.getItem('autoLogin'))
		if (autoLogin) {
			res.data.expiresInDate = moment().add(expiresIn, 'seconds').format('YYYY/MM/DD HH:mm:ss')
		} else {
			res.data.expiresInDate = moment().add(72, 'hours').format('YYYY/MM/DD HH:mm:ss')
		}
		this.tokenInfo = res.data
		this.loginStatus = true
		var cache = []; // 声明cache变量，便于匹配是否有循环引用的情况
		var replacer = function(key, value) {
		    if (typeof value === 'object' && value !== null) {
		        if (cache.indexOf(value) !== -1) {
		            return; // 移除
		        }
		        cache.push(value);  // 收集所有的值
		    }
		    return value
		}
		// https://blog.csdn.net/qq_27674439/article/details/90717791
		// JSON.stringify(value [, replacer] [, space]) 
		// replacer为方法时，那很简单，就是说把系列化后的每一个对象（记住是每一个）传进方法里面进行处理。
		localStorage.setItem('userInfo', JSON.stringify(this, replacer))
		cache = null;
		updateToken(res.data.accessToken)
	}
	
	async updateInfo() {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		let res = await api.clientInfo()
		Object.assign(userInfo, res.data)
		this.setUserInfo(userInfo)
	}
	
	// async getMessage(type) {
	// 	return await api.clientMessageList({ current:1, size: 10, type })
	// }
	async getCaptcha(){
		let res = await api.getCaptcha()
		this.captchakey = res.data.key
		return res.data.image
	}
}