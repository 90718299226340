<template>
	<div class="m-dragFile-box" ref="pushBox" :class="{ active, moai: type == 'moai'}">
		<div class="tip-text-box" @click="oninput" @change="onChange">
			<template v-if="type === 'moai'">
				<img class="tip-text-icon" src="@/assets/icon/updata_icon.png" alt="">
				<div class="tip-text">
					<p><span>上传或拖放</span>图像以用作提示图片</p>
					<p>PNG或者JPG最大5MB</p>
				</div>
			</template>
			<div class="tip-text" v-else>
				<p>拖拽或黏贴图片到这里</p>
				<p>支持32MB以内JPG、PNG格式图片</p>
			</div>
			
			<input type="file" class="file_input" ref="fileInput">
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: {
				type: String,
				default: 'header'
			}
		},
		data: () => ({
			active: false
		}),
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let dom = this.$refs.pushBox
				let hoverFlag = false
				const stopP = (e) => { 
					e.stopPropagation()
					e.preventDefault()
					this.active = true
				}
				const onPaste = (e) => {
					if (e.clipboardData && e.clipboardData.items.length && e.clipboardData.items[0].kind === 'file' && hoverFlag) {
						e.stopPropagation()
						e.preventDefault()
						this.onDrag(e)
					}
				}
				dom.addEventListener('drop',this.onDrag.bind(this), false)
				document.body.addEventListener('paste',onPaste, false)
				dom.addEventListener('dragenter',stopP, false)
				dom.addEventListener('dragover', stopP, false)
				dom.addEventListener('dragleave',(e) => { stopP(e);this.active = false; }, false)
				dom.addEventListener('mouseenter', () => { hoverFlag = true },false)
				dom.addEventListener('mouseleave', () => { hoverFlag = false },false)
			},
			onDrag(e) {
				e.stopPropagation()
				e.preventDefault()
				this.active = false
				this.$emit('change', (e.clipboardData || e.dataTransfer).files)
			},
			oninput() {
				this.$refs.fileInput.click()
			},
			onChange(e) {
				e.stopPropagation()
				e.preventDefault()
				this.active = false
				this.$emit('change', e.target.files)
			}
			
		}
	}
</script>

<style scoped lang="less">
	.m-dragFile-box{
		position: absolute;
		top: 64px;
		left: 0;
		width:100%;
		height:231px;
		background: rgba(22, 23, 24, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		padding:16px;
		z-index: 2;
		&.active,&:active{
			// box-shadow: 0 0 10px 0 rgba(0,0,0,.85) inset;
			.tip-text-box{
				.tip-text{
					// color: rgba(255,255,255,.2);
					color: rgba(152, 155, 161, 1);

				}
			}
			
		}
		.pasue_box{
			width: 100%;
			height: 100%;
			opacity: 0;
		}
		.tip-text-box{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			flex-direction: column;
			border: 1px dashed rgba(217,217,217,.6);
			.tip-text-icon{
				width: 31px;
				height:37px;
				display: block;
				margin-bottom:20px;
			}
			.tip-icon{
				width: 72px;
				height: 72px;
				display: block;
				margin: 0 auto;
				margin-bottom: 32px;
			}
			.tip-text{
				font-family: 'Microsoft YaHei';
				font-size: 16px;
				line-height: 20px;
				color: rgba(255,255,255,.2);
				text-align: center;
			}
		}
		&.moai{
			.tip-text-box{
				border: none;
				.tip-text{
					color: #fff;
					span {
						color: #2fb1ff;
					}
				}
			}
		}
	}
	.file_input{
		display: none;
	}
</style>