import api from '@/api/api.js'
import { imgDomain } from '@/api/request.js'
import { Model } from '@/model/goods/model.js'

const defaultHejiCover = require('@/assets/icon/defaultUserHeader.png')

// 
export class Heji {
	
	constructor(user) {
		this.list = []
		this.current = 1,
		this.size = 50,
		this.pages =  0,
		this.nearFutrueList = []
		this.user = user
	}
	
	async updateList (params) {
		let defaultParams = { 
				clientId_equal: this.user.id,
				current: this.current,
				size: this.size,
				...params
			}
		let res = await api.favoriteList(defaultParams)
		.catch(err => {  throw err })
		res.data.records.forEach(item => {
			if(item.cover) {
				item.cover = imgDomain + item.cover
			} else {
				item.cover = defaultHejiCover
			}
		})
		this.list.splice(0, this.list.length, ...res.data.records)
	}
	
	async updateNearFutrueList() {
		let res = await api.favoriteList({ descs: 'update_time', current: 1, size: 3, clientId_equal: this.user.id })
		.catch(err => {  throw err })
		res.data.records.forEach(item => {
			if(item.cover) {
				item.cover = imgDomain + item.cover
			} else {
				item.cover = defaultHejiCover
			}
		})
		this.nearFutrueList.splice(0, this.nearFutrueList.length, ...res.data.records)
		return this.nearFutrueList
	}
	
	async createHeji (name, content) {
		let res = await api.favoriteSubmit({ name, content })
		.catch(err => {  throw err })
		this.updateList()
		return res.data
	}
	
	async removeHeji (heji) {
		let res = await api.favoriteRemove({ ids: heji.id })
		.catch(err => {  throw err })
		this.updateList()
		return res.data
	}
	
	async updateHeji (name, content, id) {
		let res = await api.favoriteSubmit({ name, content, id })
		.catch(err => {  throw err })
		this.updateList()
		return res.data
	}
	
	async addColl (goods) {
		let res = await api.favoriteItemSave({ resourceId: goods.id  })
		goods.update()
		.catch(err => {  throw err })
		return res
	}
	
	async removeColl(goods) {
		let res = await api.favoriteItemRemove({ id: goods.favoriteItemId  })
		goods.update()
		.catch(err => {  throw err })
	} 
	
	async add (heji, goods) {
		let params
		if (goods) {
			params = { favoriteId: heji.id, resourceId: goods.id }
		} else {
			params = { resourceId: heji.id }
		}
		let res = await api.favoriteItemSave(params)
		.catch(err => {  throw err })
		return res
	}
	
	async getHejiDetail(id) {
		let res = await api.favoriteList({ id })
		res.data.records.forEach(item => {
			if(item.cover) {
				item.cover = imgDomain + item.cover
			} else {
				item.cover = defaultHejiCover
			}
		})
		return res.data.records[0]
	}
	
	async getHejiResourcesList(heji) {
		let params = {current: 1, size: 50}
		if (heji) params.favoriteId = heji.id
		let res = await api.favoriteLItemist(params)
		return res.data.records.map(item => { item.resource =  new Model(item.resource); return item})
	}
	async getCollResourcesList(params = {}) {
		let res = await api.favoriteLItemist( {current: 1, size: 50, ...params})
		let data = res.data.records.map(item => { item.resource =  new Model(item.resource); return item })
		return data
	}
	
}