import api from '@/api/api.js'


export class Order {
	constructor() {
		
		this.checkPayStatusTimer = null
		
	}
	async createWechatOrder (params, callBack) {
		let res = await api.orderWechatCreateOrder(params)
		let code = res.data.imgCode
		let payId = res.data.payId
		if(this.checkPayStatusTimer) clearInterval(this.checkPayStatusTimer)
		this.checkPayStatusTimer = setInterval(async () => {
			let res = await api.orderWechatSelect({ payId })
			if(res.data == 2) {
				clearInterval(this.checkPayStatusTimer)
				callBack&&callBack()
			}
		}, 1000)
		
		return code
	}
	
	async payModel(model) {
		let params = {}
		if (Array.isArray(model)) {
			params.resourceIds = model.map(item => item.id).join(',')
			params.buyCarIds = model.map(item => item.buyCarId).join(',')
		} else {
			params.resourceIds = model.id
			if(model.buyCarId) params.buyCarIds = model.buyCarId
		}
		let res = await api.orderPayment(params).catch(err => { throw err })
		return res
	}
}