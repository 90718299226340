class AppLoca {
	
	constructor(){
		this.openLogo = null
		this.update()
	}
	
	update(key = ['openLogo']) {
		if (typeof(key) === 'string') {
			key = [key]
		}
		key.forEach(item => {
			this[item] = JSON.parse(localStorage.getItem(item))
		})
	}
	
	save(key, value) {
		if (key === undefined && value === undefined) {
			key = Object.keys(this)
		} else {
			key = [key]
		}
		key.forEach(item => {
			localStorage.setItem(item, JSON.stringify(value || this[item]))
			if(value) this[item] = value
		})
	}
	
}

export default AppLoca