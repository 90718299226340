import { axios, service, $ajax, $xhr } from './request'

let apis = {}

let URLS = [
	// { name: 'create',    url: '/api/pai-eas/txt2img', method: 'post', contentType: 'application/json' },
	// { name: 'create',    url: '/api/comfy-ui/push',   method: 'post', contentType: 'application/json' }
	{ name: 'page', 	 url: '/api/pai-eas/page', 	  method: 'get' },
	{ name: 'paiPost',   url: '/api/pai-eas/post', 	  method: 'get' },
	{ name: 'pairemove', url: '/api/pai-eas/remove',  method: 'post'},
	{ name: 'paiDetail', url: '/api/pai-eas/detail',  method: 'get' },
	{ name: 'paiCount',  url: '/api/pai-eas/count',   method: 'get' },
	{ name: 'paiConfig', url: '/api/common/config',   method: 'get' },
	{ name: 'styles',    url: '/api/comfy-ui/style',  method: 'get' },
	{ name: 'detail',    url: '/api/comfy-ui/detail', method: 'get' },
	
]


URLS.forEach(api => {
	let { name, url, method, contentType, responseType } = api;
	let headers = { };
	if(contentType) {
		headers['Content-Type'] = contentType
	}
	apis[name] = function (data) {
		let requestParams = { url, method, headers}
		if (responseType) requestParams.responseType = responseType
		if (contentType) {
			if(method === 'post')requestParams.data = data
			if(method === 'get')requestParams.params = data
			if (contentType === 'application/json') {
				requestParams.data = JSON.stringify(data)
			}
		} else {
			requestParams.data = data
			requestParams.params = data
		}
		
		return axios(requestParams)
	}
})

apis.create = async function (params) {
	let data = new FormData()
	if (data) {
		for (var key in params) {
			if (Array.isArray(params[key])) {
				params[key].forEach((item, index) => {
					data.append(`${key}[${index}]`, item)
				})
			} else {
				data.append(key, params[key])
			}
			
		}
	}
	return axios({ url: '/api/comfy-ui/push', method: 'post', data })
}

export default apis