<template>
	<div class="confirm_mask" v-if="showConfirm">
		<div class="confirm_model_box">
			<div class="confirm_title">{{ title }}</div>
			<div class="confirm_des">{{ des }}</div>
			<img src="@/assets/icon/close.png" alt="" class="close_btn" @click="onClose">
			<div class="btn_group" :class="{ btnReverse }">
				<div class="confirm_btn right_btn" @click="_onOk"> {{ okText || '确认' }} </div>
				<div class="confirm_btn cancle_btn" @click="_onCancle">{{ cancleText || '取消' }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'confirm',
		data: () => ({
			title: null,
			des: null,
			onOk: null,
			onCancle: null,
			showConfirm: false,
			okText: null,
			cancleText: null,
			btnReverse: false
		}),
		methods: {
			show(title, des, onOk, onCancle, okText, cancleText, btnReverse) {
				this.title = title
				this.des = des
				this.onOk = onOk
				this.onCancle = onCancle
				this.showConfirm = true
				this.okText = okText
				this.cancleText = cancleText
				this.btnReverse = btnReverse
			},
			onClose() {
				this._onCancle()
			},
			_onOk(){
				if (this.onOk) this.onOk()
				this.showConfirm = false
			},
			_onCancle() {
				if (this.onCancle) this.onCancle()
				this.showConfirm = false
			}
		}
	}
</script>

<style lang="less" scoped> 

	.confirm_mask{
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		.confirm_model_box{
			min-width: 600px;
			padding: 32px;
			background-color: #161718;
			position: relative;
			.close_btn{
				width: 12px;
				height: 12px;
				position: absolute;
				top: 42px;
				right: 36px;
				cursor: pointer;
			}
			.confirm_title{
				font-size:20px;
				color: #fff;
				padding-right: 32px;
				margin-bottom: 24px;
			}
			.confirm_des{
				font-size: 12px;
				line-height: 17px;
				margin-bottom: 24px;
				color: #ABAEB4;
			}
			.btn_group{
				display: flex;
				justify-content: flex-end;
				.confirm_btn{
					height: 40px;
					padding: 0 40px;
					border-radius: 20px;
					text-align: center;
					font-size: 14px;
					color: #e1e1e2;
					border: 1px solid #494E56;
					line-height: 38px;
					margin-left: 8px;
					user-select: none;
					cursor: pointer;
					&.right_btn{
						background: @theme3-color;
						border-color: transparent;
					}
				}
				&.btnReverse{
					flex-direction: row-reverse;
					justify-content: flex-start;
					.confirm_btn{
						&.right_btn{
							background-image: none;
							border-color: #494e56;
						}
						&.cancle_btn{
							background-image: @theme3-color;
							border-color: transparent;
						}
					}
				}
			}
		}
	}

</style>