import axios from 'axios'
import app from '@/model/app.js'

const imgDomain = process.env.NODE_ENV === "production" ? 'https://mogine.oss-cn-shanghai.aliyuncs.com/' : 'https://mogine.oss-cn-shanghai.aliyuncs.com/'

let token

// 创建 axios 实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url '/'
  baseURL: process.env.NODE_ENV === "production" ? '/mogine/api' : '/'
  // timeout: 6000 // 请求超时时间
})
console.log(process.env.NODE_ENV)
// response interceptor
service.interceptors.response.use((response, config) => {
  const status = response.data.code || 200
  const message = response.data.msg || '未知错误';
  if (response.data.code === 401) { 
	// todo token 失效 退出登录 刷新页面
	// if(token) {
		// store.dispatch('Logout', '请求成功 token 过期 登出').then(() => {
		//   window.location.reload()
		// })
	// }
	console.log( `request code is not 200, code ${status}, error msg ${response.data.msg}` )
	app.user.logout()
	token = null
	return Promise.reject(new Error(message))
  } else if (status != 200) {
    console.log( `request code is not 200, code ${status}, error msg ${response.data.msg}` )
    // notification.error({
    //   message: '提示',
    //   description: response.data.msg
    // })
    return Promise.reject(new Error(message))
  } else {
    // console.log('request response end access token is', !!Vue.ls.get(ACCESS_TOKEN))
    return response.data
  }
})

// request interceptor
service.interceptors.request.use(config => {
  if (token) {
    config.headers['Blade-Auth'] = `bearer ${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  config.headers['Authorization'] = 'Basic YXBpOmFwaV9zZWNyZXQ='
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  return config
})

function updateToken(newToken) {
	token = newToken
}

const $ajax = function (params) {
	return new Promise((resolve,reject) => {
		if (!params.headers) {
			params.headers = []
		}
		if (token) {
		  params.headers['Blade-Auth'] = `bearer ${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
		}
		params.headers['Authorization'] = 'Basic YXBpOmFwaV9zZWNyZXQ='
		params.success = function(res) {
			resolve(res)
		}
		params.error = function (err) {
			reject(err)
		}
		$.ajax(params)
	})
	
}

const $xhr = function ({ url, methods, responseType }) {
	return new Promise((resolve, reject) => {
		var request = new XMLHttpRequest();
		request.open(methods, url, true);
		if (token) {
		  request.setRequestHeader('Blade-Auth', `bearer ${token}`) // 让每个请求携带自定义 token 请根据实际情况自行修改
		}
		request.setRequestHeader('Authorization', 'Basic YXBpOmFwaV9zZWNyZXQ=')
		if (responseType) request.responseType = responseType;
		request.onload = function() {
		   resolve(request.response)
		};
		request.send();
	})
}

const getResPonseSize = (url) => {
	return new Promise((resolve, reject) => {
		var xhr = new XMLHttpRequest();
		xhr.open('HEAD', url, true); 
		xhr.onreadystatechange = function() {
			if ( xhr.readyState == 4 ) {
				if ( xhr.status == 200 ) {
					resolve(xhr.getResponseHeader('Content-Length'))
					// alert('Size in bytes: ' + xhr.getResponseHeader('Content-Length'));     
				} else {
					// alert('ERROR');
					reject('get ResPonse Size error')
				}   
			} 
		}; 
		xhr.send(null);
	})
}

export {
	service as axios,
	updateToken,
	imgDomain,
	axios as service,
	$ajax,
	$xhr,
	getResPonseSize
}