import api from '@/api/api.js'

class Dic {
	constructor() {
		this.list = {}
	}
	async getDicByCode(code) {
		let dic = null
		if (this.list[code]) {
			dic = this.list[code]
		} else {
			console.log(api)
			let res = await api.dic({ code })
			dic = res.data
			dic.forEach(item => {
				item.id = item.dictKey
			})
			this.list[code] = dic
		}
		
		return dic
	}
}

export default new Dic()