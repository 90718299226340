<template>
	<div class="pay_qrCode_main" @click="onClose" v-if="orderInfo.show">
		<div class="goods_info">
			<div class="date_text" v-if="orderInfo.product === 'vip'">一{{ orderInfo.vipType === 1 ? '个月' : '年' }}</div>
			<!-- <div class="price" v-if="orderInfo.product === 'vip'">￥{{`${orderInfo.money}/${orderInfo.vipType === 1 ? '月' : '年'}`}}</div> -->
			<div class="date_text" v-if="orderInfo.product === 'point'">{{ orderInfo.point }}积分</div>
			<!-- <div class="price" v-if="orderInfo.product === 'point'">￥{{orderInfo.money}}</div> -->
			<!-- <div class="oldPrice">￥63/月</div> -->
		</div>
		<div class="pay_qrCode_box">
			<div class="wechat_qrCode_box">
				<img class="pay_qrCode_img" :src="imgCode" alt="">
				<div class="qrCode_texts">
					<div class="wechatPay">微信</div>
					<!-- <div class="aliPay">支付宝</div> -->
				</div>
			</div>
			
			<div class="pay_info">
				<div class="pay_info_item">
					<div class="pay_label">金额</div>
					<div class="pay_text" v-if="orderInfo.product === 'vip'">￥{{ orderInfo.money }}/月</div>
					<div class="pay_text red" v-if="orderInfo.product === 'point'">￥{{ orderInfo.money }}</div>
				</div>
				<div class="pay_info_item" v-if="orderInfo.product === 'point'">
					<div class="pay_label">积分</div>
					<div class="pay_text">{{ orderInfo.point }}</div>
				</div>
				<div class="pay_info_item" v-if="orderInfo.product === 'vip'">
					<div class="pay_label">有效期限</div>
					<div class="pay_text">1个{{ orderInfo.vipType === 1 ? '月' : '年' }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex'
	export default {
		name: 'payQrcode',
		model: {
			prop: ['orderInfo'],
			event: 'change'
		},
		props: ['orderInfo'],
		data: () => {
			return {
				imgCode: null
			}
		},
		methods: {
			...mapMutations('pay', ['changeVPVisible']),
			onClose() {
				this.orderInfo.show = false
				this.$emit('change', this.orderInfo)
			},
			async getPayCode () {
				this.imgCode = 'data:image/png;base64,' + await this.App.order.createWechatOrder(this.orderInfo, this.payCallBack.bind(this))
			},
			payCallBack() {
				this.$message.success('支付成功')
				// this.user.
				if (this.App.order.checkPayStatusTimer) {
					clearInterval(this.App.order.checkPayStatusTimer)
					this.App.order.checkPayStatusTimer = false
				}
				this.onClose()
				// this.changeVPVisible()
				this.user.updateInfo()
				this.$emit('success', true)
			}
		},
		watch: {
			'orderInfo.show':function () {
				if(this.orderInfo.show){
					this.getPayCode()
				} else {
					if (this.App.order.checkPayStatusTimer) {
						clearInterval(this.App.order.checkPayStatusTimer)
						this.App.order.checkPayStatusTimer = false
					}
				}
				
			}
		}
	}
</script>

<style scoped lang="less">
	.pay_qrCode_main{
		// background-color: #fff;
		background: linear-gradient(202.35deg, #EAF7FF 0%, #2FB1FF 100%);
		padding: 6px;
		width: 302px;
		height: 370px;
		.pay_qrCode_box{
			background-color: #fff;
			padding: 12px;
			padding-top: 30px;
		}
		.goods_info {
			text-align: center;
			line-height: 45px;
			font-size: 16px;
			text-align: left;
			
		}
		.wechat_qrCode_box{
			width: 151px;
			height: 181px;
			border: 1px solid #f5f5f5;
			margin: 0 auto;
			left: 0;
			right: 0;
			padding-top: 20px;
		}
		.pay_qrCode_img{
			width: 110px;
			height: 110px;
			display: block;
			left:0;
			right:0;
			margin: 0 auto;
			margin-bottom: 9px;
		}
		.qrCode_texts{
			display: flex;
			justify-content: center;
			font-size: 14px;
			line-height: 23px;
			.wechatPay {
				padding-left: 30px;
				background: url(@/assets/icon/wechatPay.png) no-repeat left center;
				background-size: 23px 23px;
				margin-right: 12px;
				color: #1FA747;

			}
			.aliPay {
				padding-left: 30px;
				background: url(@/assets/icon/alipay.png) no-repeat left center;
				background-size: 23px 23px;
			}
		}
		.pay_info {
			font-size: 14px;
			line-height: 24px;
			padding: 10px 0;
			padding-bottom: 15px;
			.pay_info_item {
				display: flex;
				justify-content: space-between;
				margin-bottom: 8px;
				.price {
					font-weight: 700;
					font-size: 24px;
					line-height: 24px;
				}
				.pay_text{
					&.red{
						font-weight: bold;
						color: #F04C27;
						font-size: 24px;
					}
				}
			}
		} 
	}
</style>