import { render, staticRenderFns } from "./userInfo.vue?vue&type=template&id=542e9d46&scoped=true&"
import script from "./userInfo.vue?vue&type=script&lang=js&"
export * from "./userInfo.vue?vue&type=script&lang=js&"
import style0 from "./userInfo.vue?vue&type=style&index=0&id=542e9d46&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542e9d46",
  null
  
)

export default component.exports