<template>
	<div class="slide_bar">
		<div class="slide-bar-item" v-for="index in length" :style="styleChange(index)" @click="onSwiperTo(index)"></div>
	</div>
</template>

<script>
	export default {
		name: 'swiperBar',
		props: [ 'current', 'length' ],
		methods: {
			styleChange(index) {
				let mr = this.isMobile ? 4 : 16
				let w = this.isMobile ? 10 : 40
				index += -1
				if (index) {
					if (index <= this.current ) {
						return `transform:translateX(-${(this.current - index + 1) * mr + w}px);margin:0px;`
					} else {
						return ''
					}
				} else {
					return `transform:translateX(${this.current * mr / 2}px);margin:0px;`
				}
			},
			onSwiperTo(index) {
				this.$emit('change', index > this.current + 1 ? index: index - 1)
			}
		}
	}
</script>

<style scoped lang="less">
	.slide_bar{
		position: absolute;
		bottom: 24px;
		right: 40px;
		display: flex;
		z-index: 1000;
		.slide-bar-item{
			width: 8px;
			height: 8px;
			margin-left: 16px;
			background-color: rgba(255,255,255,.5);
			transition: all 2s;
			cursor: pointer;
			&:first-child{
				width: 40px;
				height: 8px;
				background-color: #fff;
			}
		}
	}
	@media screen and (max-width: 760px) {
		.slide_bar{
			.slide-bar-item{
				width: 2px;
				height: 2px;
				margin-left: 4px;
				&:first-child{
					width: 10px;
					height: 2px;
				}
			}
		}
	}
</style>