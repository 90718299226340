import api from '@/api/api.js'
import { imgDomain } from '@/api/request.js'
import { Goods } from './goods.js'
import Dic from '@/model/dic.js'


export class Model extends Goods {
	constructor(info) {
		super(info)
		this.BaseInfo = info
    	this.init()
	}
	
	init () {
		this.getDic()
		this.setModelInfo(this.BaseInfo)
	}
	
	async getDic() {
		if (!this.colors.length && !this.loadColors) {
			this.getColors()
		}
		return
	}
	
	setModelInfo(info) {
		Object.keys(info).forEach(key => {
			let value = info[key]
			if (value || value === 0) {
				switch(key) {
					case 'cover':
						this.imgs = value.split(',').map(item => imgDomain + item)
						this.cover = this.imgs[0] + '?x-oss-process=style/500'
					break;
					case 'tags':
						this.tags = value.replaceAll('，', ',').split(',')
					break;
					case 'ancestorsName':
						this.ancestorsName = value.replaceAll('，', ',').split(',')
						this.topTypeName = this.ancestorsName[0]
						if (!info.resourceUrl) {
							let formato = 'zip'
							switch (this.topTypeName) {
								case '模型':
									formato = 'usdz'
								break;
								case '场景':
									formato = 'usdz'
								break;
								case '全景图':
									formato = 'hdr'
								break;
								case '材质':
									formato = 'usdz'
								break;
							}
							this.resources = [
								{ formato , url: null, name: null }
							]
						}
					break;
					case 'resourceUrl':
						this.resources = value.split(',').map(item => {
							let formato = item.split('.').pop()
							if (formato === 'zip') formato = 'usdz'
							return {
								formato,
								url: item,
								name: item.split('/').pop()
							}
						})
						// this.glbUrl = resource.find(item => /(glb)$/g.test(item)) ? imgDomain + resource.find(item => /(glb)$/g.test(item)) : null
						// this.usdzUrl = resource.find(item => /(usdz)$/g.test(item)) ? imgDomain + resource.find(item => /(usdz)$/g.test(item)) : null
						// this.zipUrl = resource.find(item => /(zip)$/g.test(item)) ?imgDomain + resource.find(item => /(zip)$/g.test(item)) : null
					break;
					case 'clientId':
						this.clientId = value
						if(!this.clientId) {
							this.clientName = 'Mogine'
							this.clientHeadImage = require('@/assets/icon/defaultUserHeader.png')
						}
						
					break;
					case 'color':
						this.colorHex = ''
						if (value && this.colors.length) {
							this.colorHex = value.split(',').map(item => {
								this.colors.find(color => color.id == item)
							})
						}
					break;
					
					default:
						this[key] = value
				}
			} else {
				this[key] = value
			}
			
		})
	}
	
	async getRelevantHeji() {
		let res = await api.favoriteList({ current: 1, size: 1, resourceId: this.id })
		res.data.records.forEach(item => {
			if(item.cover)item.cover = imgDomain + item.cover
		})
		return res.data.records[0]
	}
	
	async getRecommendModel( size = 4 ) {
		let res = await api.resourceList({ type: this.type, size, current: 1 })
		res.data.records.forEach(item => {
			if(item.cover)item.cover = imgDomain + item.cover
		})
		return res.data.records
	}
	
	async update() {
		let res = await api.resourceList({ id: this.id })
		this.setModelInfo(res.data.records[0])
	}
	
	async downLoad(resource, onProgress) {
		// switch (type) {
		// 	case  'glb':
		// 		resourseUrl = getKey(/(glb)$/g)
		// 	break;
		// 	case  'usdz':
		// 		resourseUrl = getKey(/(usdz)$/g) 
		// 	break;
		// 	case  'usd': 
		// 		resourseUrl = getKey(/(zip)$/g)
		// 		// resourseType = 'application/zip'
		// 	break;
		// }
		if(!resource) return 
		let res = await api.downloadAli({key: resource.url}, onProgress)
		// let blob = new Blob([res], { type: resourseType })
		let url = window.URL.createObjectURL(new Blob([res]))
		let link = document.createElement('a')
		link.style.display= 'none'
		link.href=url
		link.setAttribute('download', resource.name)
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		window.URL.revokeObjectURL(url)
		
		// let a = document.createElement('a')
		// a.download = fileName
		// a.href = `/api/client/download/qiniu?key=${resourseUrl}`
		// document.body.appendChild(a)
		// a.click()
		// document.body.removeChild(a)
	}
	
	static typeList = []
	
	static async initGoodsTypeList() {
		let res = await api.resourceTypeList()
		this.typeList.push(...res.data)
	}
	
	static findTypeById(id) {
		let typeItem = null
		let forFn = ( list ) => {
			list.forEach(item => {
				if (item.id === id) {
					typeItem = item
				} else if ( item.children ) {
					forFn(item.children)
				}
			})
		}
		forFn(this.typeList)
		return typeItem
	}

}

Model.prototype.colors = []
Model.prototype.loadColors = false
Model.prototype.getColors = async () =>{
	Model.prototype.loadColors = true
	let res = await Dic.getDicByCode('color')
	.catch(err => {
		Model.prototype.loadColors = false
		throw err
	})
	Model.prototype.colors = res
	console.log(res)
	return res
}