<template>
	<div class="base_layouts">
		<mHeader></mHeader>
		<!-- <div class="logoMask" v-if="logoHide">
			<img class="main_home_logo" alt="mogine logo" src="@/assets/home_logo.png">
		</div>
		<div class="baseLoadLogo" v-else-if="!logoHide && baseLoad">
			<img class="main_home_logo" alt="mogine logo" src="@/assets/logo.png">
		</div> -->
		<template>
			<router-view class="base_layouts_content" />
		</template>
		
	</div>
</template>

<script>
	import mHeader from '@/components/index/header.vue'
	let timer
	export default {
		name: 'logoShow',
		components: {
			mHeader
		},
		data: () => {
			return {
				logoHide: true,
				baseLoad: true
			}
		},
		async created() {
			// let { openLogo } = this.loca
			// if (!(openLogo && openLogo > (new Date().getTime() - 3600 * 24 * 3 * 1000))) {
			// 	this.logoHide = true
			// } else {
			// 	this.logoHide = false
			// 	this.loca.save('openLogo', null)
			// }
			await this.user.autoLogin().catch(() => {
				this.$router.replace({path: this.$route.path})
			})
		
			this.baseLoad = false
		},
		mounted() {
			  this.hideLogo()
		},
		methods: {
			hideLogo() {
				if (this.logoHide) {
					timer = setTimeout(() => {
						this.logoHide = false
						clearTimeout(timer)
						// this.$router.push('/index')
						this.loca.save('openLogo', new Date().getTime())
					}, 5200) 
				}
				
			},
		}
	}
</script>

<style scoped lang="less">
	.base_layouts{
		background-color: #000;
	}
	.base_layouts_content{
		width: 100vw;
		min-width: 1200px;
		height: 100vh;
		overflow-y: auto;
	}
	.logoMask{
		background: #000;
		position: fixed;
		top:0;
		left:0;
		// animation: hide 1s ease 5s 1;
		z-index: 9999;
		display: block;
		.main_home_logo{
			width: 100vw;
			height: 100vh;
			object-fit: cover;
			display: block;
			-webkit-user-drag: none;
		}
	}
	.baseLoadLogo{
		background: #000;
		position: fixed;
		top:0;
		left:0;
		// animation: hide 1s ease 5s 1;
		z-index: 9999;
		display: flex;
		width: 100vw;
		height: 100vh;
		align-items: center;
		justify-content: center;
		.main_home_logo{
			height: 100px;
			object-fit: cover;
			display: block;
			-webkit-user-drag: none;
		}
	}
</style>

<style scoped lang="less">
	@media screen and (max-width:760px) {
		.base_layouts_content{
			min-width: 100vw;
			overflow-x: hidden;
			padding-top: 60px;
		}
	}
</style>