import api from '@/api/api.js'

export class Goods {
	constructor(info) {
		this.setGoodsInfo(info)
	}
	setGoodsInfo(info) {
		if(info){
			Object.keys(info).forEach(key => {
				this[key] = info[key]
			})
		}
	}
}