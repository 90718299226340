<template>
	<div class="m-header-search-main">
		<div class="m-header-search-box">
			<img src="@/assets/index/header/search.png" alt="" class="input-search-icon">
			<img src="@/assets/index/header/search.png" alt="" class="input-search-icon input-mobile-search-icon" @click="onLink('/search')">
			<input type="text" placeholder="搜索模型、材质、HDR等" class="header-search-input" v-model="value" @keydown.enter="onSearch">
			<img src="@/assets/index/header/camera.png" v-if="!showFileView" alt="" class="input-search-photo-icon" @click="onShowFileView">
			<img src="@/assets/index/header/camera_active.png" v-else alt="" class="input-search-photo-icon" @click="onShowFileView">
		</div>
		<dragFileView v-if="showFileView"></dragFileView>
	</div>
</template>

<script>
	import dragFileView from './dragFileView.vue'
	
	export default {
		name: 'headerSearch',
		components: {
			dragFileView
		},
		data: () => ({
			showFileView: false,
			value: null
		}),
		methods: {
			onShowFileView() {
				this.showFileView = !this.showFileView
			},
			onSearch() {
				this.$emit('search', this.value)
			}
		}
		
	}
</script>

<style scoped lang="less">
	.m-header-search-main{
		position: relative;
		flex: 0 0 auto;
		.m-header-search-box{
			height: 48px;
			position: relative;;
			.input-search-icon{
				width: 32px;
				height: 32px;
				position: absolute;
				left: 16px;
				top: 8px;
			}
			.header-search-input{
				height: 48px;
				width: 100%;
				background: rgba(22, 23, 24, 1);
				outline: none;
				border: none;
				padding:0 64px;
				line-height: 22.4px;
				font-size: 16px;
				color: #fff;
				display: block;
				box-sizing: border-box;
			}
			.input-search-photo-icon{
				width: 48px;
				height: 48px;
				position: absolute;
				right: 16px;
				top: 0px;
				cursor: pointer;
			}
		}
	}
</style>

<style scoped lang="less">
	@media screen and (max-width: 760px) {
		.m-header-search-main{
			width: 32px;
			flex: 0 0 auto;
			.m-header-search-box{
				.input-search-photo-icon,.header-search-input,.input-search-icon{
					display: none;
				}
				.input-mobile-search-icon{
					left: 0;
					display: block;
				}
			}
		}
	}
</style>