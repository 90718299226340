import api from '@/api/moai.js'
import { updateToken, imgDomain,getResPonseSize } from '@/api/request.js'
import router from '@/router'

let tagJson = require('@/model/moai/lora_style.json')
let ideaprompt = require('@/model/moai/ideaprompt.json')

let createNum = 0
class MoAi {
	constructor() {
		this.createPic = null
		this.tagJson = tagJson
		this.createParams = null
		this.n_prompt =ideaprompt.n_prompt
		// this.updateCount()
		// this.getConfig()
	}
	async create(prompt, negativePrompt, lora, imageFile, batchSize = 2) {
		let payload = {
			prompt,
			batchSize,
		}
		if (!prompt) throw '没有描述'
		// if (createNum >= 5) throw 'bad create'
		if (negativePrompt) payload['negativePrompt'] = negativePrompt
		if (imageFile) {
			payload['files[0]'] = imageFile
		}
		if (lora) {
			payload.loras = [lora]
			payload.flowName = 't2i_l_api'
		} else if (imageFile) {
			payload.flowName = 't2i_cn_api'
			payload.interStyleId = 8
		} else {
			payload.flowName = 't2i_nl_api'
			payload.interStyleId = 8
		}
		// 
		let res = await api.create(payload).catch((err) => {
			throw(err)
		})
		// createNum ++
		this.createParams = {
			prompt, negativePrompt, imageFile, id: res.data.id
		}
		let dRes = await this.getImage(res.data.task_id)
		console.log(dRes)
		let arr = dRes.imgUrl.split(',')
		let okArr = []
		for(var i = 0; i < arr.length; i++) {
			var item = imgDomain + arr[i]
			if (i > arr.length - batchSize - 1) {
				okArr.push(item)
			}
		}
		this.createPic = okArr
		return this.createPic
	}
	getImage(id) {
		
		return new Promise((resolve, reject) => {
			let timer = setInterval(() => {
				api.detail({ id, onlyView: 1})
				.then((res) => {
					if (res.data.status == 2) {
						
						clearInterval(timer)
						reject('失败')
					}
					if (res.data.status == 3) {
						clearInterval(timer)
						resolve(res.data)
					}
				})
				.catch((err) => {
					clearInterval(timer)
					reject(err)
				})
			}, 1000)
		})
	}
	
	async list(params) {
		let res = await api.page(params).catch(err => {
			throw err
		})
		res.data.records.forEach(item => {
			let { headImage } = item
			item.headImage = headImage ? (headImage.indexOf('http') === -1 ? imgDomain + headImage : headImage) : require('@/assets/icon/defaultUserHeader.png')
			let url = item.imgUrl
			item.title = item.prompt.split(',').filter(item => !this._desFilter(item)).join(',')
			item.images = url.split(',').filter(item => item).map(item => (imgDomain + item))
			item.cover = item.images[0]
			item.undes = item.negativePrompt.split(',').filter(item => !this._desFilter(item))
		})
		return res
	}
	async detail(id) {
		let res = await api.paiDetail({ id })
		let detail = res.data
		let { headImage } = detail
		detail.headImage = headImage ? (headImage.indexOf('http') === -1 ? imgDomain + headImage : headImage) : require('@/assets/icon/defaultUserHeader.png')
		let url = detail.imgUrl
		detail.title = detail.prompt.split(',').filter(item => !this._desFilter(item)).join(',')
		detail.images = url.split(',').filter(item => item).map(item => (imgDomain + item))
		detail.cover = detail.images[0]
		detail.undes = detail.negativePrompt.split(',').filter(item => !this._desFilter(item))
		return detail
	}
	async delete(id) {
		return await api.pairemove({id})
	}
	async post (id) {
		return await api.paiPost({ id })
	}
	setParamsCreate({ des, undes }) {
		router.push({ path: '/MoAi/create', query: { des, undes } })
	}
	async updateCount() {
		let res = await api.paiCount().catch(err => {
			throw err
		})
		this.count = res.data
	}
	randomDes() {
		let { idea } = ideaprompt
		return idea[parseInt(Math.random() * 10)]
	}
	_desFilter(des) {
		if  (!des)  return true
		let p = [ '(',')','{','}','<','>' ]
		let flag = false
		p.forEach(item => {
			if (des.indexOf(item) != -1) flag = true
		})
		return flag
	}
	async getConfig() {
		let res = await api.paiConfig()
		this.maxCount = res.data[1].paramValue
		this.createPrice = res.data[0].paramValue
	}
	async getStyles() {
		let res = await api.styles()
	}
}

export default new MoAi()