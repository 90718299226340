import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/use/'
require('@/model/app.js')
require('@/utils/domEnterViewAnimation.js') 
require('@/utils/remAutoComputed.js')
import "@/assets/css/base.less";
import "@/assets/css/animation.less";
import moai from '@/model/moai/MoAI.js'


Vue.config.productionTip = false

Vue.mixin({
	
	data: () => ({
		isMobile: /mobile/i.test(navigator.userAgent),
		moai: moai,
	}),
	
	methods: {
		onLink(route){
			console.log('onLink', route)
			let isHttp = typeof(route) === 'string' && route.indexOf('http') !== -1
			if (isHttp) {
				let mogineUrl =  process.env.NODE_ENV === "production" ? 'http://www.shmogine.com/': 'http://192.168.1.150:8001/'
				if (route.indexOf(mogineUrl) !== -1 && this.user.loginStatus) {
					let tokenInfo = {...this.user.tokenInfo}
					delete tokenInfo.refreshToken
					window.location.href= route + (route.indexOf('?') === -1 ? '?' : '&') + 'tokenInfo=' + JSON.stringify(tokenInfo)
				} else {
					window.location.href = route
				}
				
			} else {
				route && this.$router.push(route)
			}
			
		}
	}
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
