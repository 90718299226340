<template>
	<div class="user-info-main">
		<div class="user-info-box">
			<div class="user-name" @click="onLink('/mogine/userCenter')">{{ user.nickname }}</div>
			<div class="user-mail">{{ user.email }}</div>
		</div>
		
		<div class="menu-item">
			<div class="menu-lable">资产已下载</div>
			<div class="menu-content">0/1000</div>
			<div class="menu-tip">资产下载已达上限,可购买下载</div>
		</div>
		<div class="menu-item">
			<div class="menu-lable">已订阅</div>
			<div class="menu-content">个人基础版</div>
			<div class="menu-tip">订阅有效期至：2022年9月6日</div>
		</div>
		<div class="menu-item">
			<div class="menu-lable">设置</div>
			<div class="menu-content"></div>
		</div>
		<!-- <div class="order-info-box">
			<div class="order-info-title">去订阅</div>
			<div class="order-info-des">新用户订阅限时折扣，低至￥98/年</div>
			<div class="order-right-list">
				<div class="order-right-item">正版个人商用授权</div>
				<div class="order-right-item">一键导出DCC软件</div>
				<div class="order-right-item">资产海量下载</div>
			</div>
		</div> -->
		<div>
			<!-- <div class="info-btn action">订阅</div> -->
			<div class="info-btn" @click="onLogout">退出登录</div>
		</div>
		
	</div>
</template>

<script>
	import { mapMutations } from 'vuex'
	export default {
		methods: {
			onLogout() {
				if(window.confirm('是否退出')) {
					this.user.logout()
					this.$message.success('注销成功')
					this.$router.push('/')
				}
			},
		}
	}
</script>

<style scoped lang="less">
	@keyframes userInfoEnter {
		0%{
			opacity: 0;
			transform: translate(100%, -10%);
		}
		100%{
			opacity: 1;
			transform: translate(0,0);
		}
	}
	.user-info-main{
		width: 305px;
		background-color: rgba(22, 23, 24, 1);
		border: 1px solid rgba(43, 46, 50, 1);
		padding: 24px 0;
		color: #fff;
		font-size: 14px;
		position: absolute;
		top: 66px;
		right: 0;
		animation: userInfoEnter .3s;
		z-index: 999;
		&>div{
			padding: 0 24px;
		}
		.user-info-box{
			height: 70px;
			border-bottom: 1px solid rbg(14,15,16);
			.user-name{
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 6px;
				cursor: pointer;
			}
			.user-mail{
				font-size: 14px;
				line-height: 20px;
			}
		}
		.menu-item{
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			color: rgb(171,174,180);
			position: relative;
			height: 44px;
			.menu-tip{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 100%;
				background-color: rgb(34,36,38); 
				padding: 16px;
				overflow: hidden;
				display: none;
			}
		}
		.info-btn{
			width: 100%;
			height: 40px;
			text-align: center;
			border-radius: 20px;
			line-height: 40px;
			border: 1px solid #e1e1e2;
			margin-top: 16px;
			cursor: pointer;
			&.action{
				border: none;
				background-image: @theme3-color;
			}
		}
	}
</style>