import confirmBox from './confirm.vue'
let confirmBoxInstance

export default {
	install(Vue) {
		Vue.component(confirmBox.name, confirmBox)
		Vue.prototype.$confirm = ({ title, des, onOk, onCancle, okText, cancleText, btnReverse }) => {
			let constructor = Vue.extend(confirmBox)
			if (!confirmBoxInstance) {
				confirmBoxInstance = new constructor(constructor).$mount()
				document.body.appendChild(confirmBoxInstance.$el)
			}
			
			confirmBoxInstance.show(title, des, onOk, onCancle, okText, cancleText, btnReverse)
		}
	}
}