import api from '@/api/api.js'
import { Model } from '@/model/goods/model.js'

export class BuyCart {
	constructor() {
		this.list = []
		this.pages = 0
		this.current = 1
		this.size = 6
		this.search = null
	}
	
	async add (goods) {
		let res = await api.addBuyCart({ resourceId: goods.id })
		goods.update()
		return res
	}
	
	async del (goods) {
		let res = await api.delBuyCart({ ids: goods.buyCarId })
		goods.update()
		return res
	}
	
	async update() {
		let { current, size, saerch } = this
		let params = { current, size }
		if (saerch) {
			params.title = search
		}
		let res = await api.BuyCartList(params)
		res.data.records.forEach(item => {
			let { resourceId, id } = item
			item.id = resourceId
			item.buyCarId = id
		})
		this.list = res.data.records.map(item => new Model(item))
		this.pages = res.data.pages
		return res
	}
}