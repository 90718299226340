import messageBox from './message.vue'
let messageBoxInstance

export default {
	install(Vue) {
		Vue.component(messageBox.name, messageBox)
		Vue.prototype.$message = {
		    warn(message, time) {
		        Vue.prototype.$messageShow({ message, type: "warn" });
		     },
		    success(message, time) {
		        Vue.prototype.$messageShow({ message, type: "success" });
		    },
		    error(message, time) {
		        Vue.prototype.$messageShow({ message, type: "error" });
		    },
		    default(message, time) {
		        Vue.prototype.$messageShow({ message, type: "default" });
		    },
		};
		Vue.prototype.$messageShow = ({ message, type, time }) => {
			let constructor = Vue.extend(messageBox)
			if (!messageBoxInstance) {
				messageBoxInstance = new constructor(constructor).$mount()
				document.body.appendChild(messageBoxInstance.$el)
			}
			
			messageBoxInstance.show(message, type, time)
		}
	}
}