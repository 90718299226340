<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
	import { Model } from '@/model/goods/model.js'
	export default {
		components: {
		},
		created() {
			// this.user.autoLogin()
			Model.initGoodsTypeList()
		}
	}
</script>

<style lang="less">
	
</style>