export function getUrlValue (name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}

export function deepFor (object1, object2, forFn) {
	for ( let key of  Object.keys(object2)) {
		let value = object2[key]
		if (object1[key] && typeof object1[key] === 'object'  && value && typeof value === 'object') {
			deepFor(object1[key], object2[key], forFn)
		} else {
			forFn(object1, key, value)
		}
	}
}

export function copyText(text) {
	console.log(text)
	var el = createElement(text)
	el.select()
	el.setSelectionRange(0,el.value.length)
	document.execCommand('copy')
	el.remove()
	// alert('已复制到剪切板')
}

function createElement(Text) {
	var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
	var element = document.createElement('textarea')
	element.style.fontSize = '12pt'
	element.style.border = '0'
	element.style.padding = '0'
	element.style.margin = '0'
	element.style.position = 'absolute'
	element.style[isRTL ? 'right' : 'left'] = '-9999px';
	let yPosition = window.pageYOffset || document.documentElement.scrollTop
	element.style.top = `${yPosition}px`
	element.setAttribute('readonly', '')
	element.value = Text
	document.body.appendChild(element)
	return element
}