<template>
	<div class="copy_content" :class="copyBodyClass">
		<!-- <p>&copy; 上海摩泛科技有限公司 <span v-if="!isMobile">All Rights Reserved. Designed by Shanghai Mofan Technology Co., Ltd.</span></p>
		<p>  </p> -->
		<div class="copyRight_left">
			<div class="company_name">© 上海摩泛科技有限公司</div>
			<div class="footer_navs">
				<div class="footer_nav" v-for="nav in navs" :key="nav.text" @click="onNavLink(nav)"> {{nav.text}} </div>
			</div>
		</div>
		<div class="copyRight_right">
			<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902002921" style="text-decoration:none;height:20px;line-height:20px;">
				<img src="@/assets/gongan.png" style="vertical-align:bottom;"/>
				沪公网安备 31010902002921号
			</a> 
				&nbsp;&nbsp;&nbsp;
			<a href=" http://beian.miit.gov.cn/" target="_blank" >
				沪ICP备18014516号-6
			</a>
		</div>
		
	</div>
</template>

<script>
	export default {
		props: ['tema'],
		data: () => ({
			
			navs: [
				{ text: '隐私声明', path: null },
				{ text: '付款协议', path: null },
				{ text: '用户使用协议', path: null },
				{ text: 'COOKIES协议', path: null },
				{ text: '问题反馈', path: null },
				{ text: '联系我们', path: '/serve' },
			]
			
		}),
		computed:{
			copyBodyClass() {
				let { tema, themeClass } = this
				let obj = {}
				if (tema) obj[tema] = true
				if (themeClass) obj['black-theme'] = true
				return obj
			}
		},
		methods: {
			onNavLink(nav) {
				if (nav.path) {
					this.$router.push(nav.path)
				} else {
					this.$message.default('网页制作中，敬请期待！')
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.copy_content{
		font-size: 12px;
		bottom:0;
		width: 100%;
		height: 60px;
		line-height: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: rgba(171, 174, 180, 1);
		padding: 22px 40px;
		a{
			color: rgba(171, 174, 180, 1);
		}
		.copyRight_left{
			display: flex;
			align-items: center;
			.company_name{
				margin-right: 9px;
			}
			.footer_navs{
				display: flex;
				.footer_nav{
					margin: 0 12px;
					position: relative;
					cursor: pointer;
					&:after{
						content: '';
						width: 1px;
						height: 16px;
						border-right: 1px solid rgba(171,174,180,1);
						display: block;
						position: absolute;
						right: -12px;
						top: 50%;
						transform: translateY(-50%);
					}
					&:last-child{
						&:after{
							display: none;
						}
					}
				}
			}
		}
		
	}
	@media screen and (max-width: 760px) {
		.copy_content{
			flex-direction: column;
			.copyRight_left{
				flex-direction: column;
				.footer_navs{
					flex-wrap: wrap;
					justify-content: center;
					.footer_nav{
						margin: 0 8px;
					}
				}
			}
			.copyRight_right{
				text-align: center;
			}
		}
	}
</style>