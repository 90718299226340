<template>
	<div class="point_vip_main black-theme" v-if="vipPointType" @mousewheel.prevent >
		<div class="point_vip_header">
			<div class="type_item" :class="{ active: vipPointType === 'point' }" @click="changeVPVisible('point')">积分充值</div>
			<div class="type_item" :class="{ active: vipPointType === 'vip' }"  @click="changeVPVisible('vip')">会员开通</div>
			<img class="close_btn" src="@/assets/icon/close.png" @click="changeVPVisible(false)">
		</div>
		<div class="point_vip_list">
			 <div class="vip_list" v-if="vipPointType === 'vip'">
				 <div class="vip_item">
					 <div class="vip_item_box">
						 <div class="vip_info">
							 <div class="vip_type">试用版</div>
							 <div class="vip_des">非会员用户</div>
							 <div class="vip_price">免费</div>
						 </div>
						 <div class="vip_content">
							 <div class="vip_content_item" :class="{ greenIcon: user.vip != 1 }">页面浏览</div>
						 </div>
					 </div>
					 <!-- <div class="m-btn black">开始</div> -->
				 </div>
				 <div class="vip_item">
				     <div class="vip_item_box">
				    	 <div class="vip_info">
				    		 <div class="vip_type">月度会员</div>
				    		 <div class="vip_des">订阅停止后积分冻结</div>
				    		 <div class="vip_price">
								 <p>￥198/月</p>
								 <p class="oldPrice">可下载500个模型/月</p>
							 </div>
							 <!-- <div class="vip_gift">赠送40积分</div> -->
							
				    	 </div>
						 <div class="vip_discount">
						 								 <p>热门推荐</p>
						 								 <p>100%</p>
						 </div>
				    	 <div class="vip_content">
				    		 <div class="vip_content_item" :class="{ greenIcon: user.vip != 1 }">页面浏览</div>
							 <div class="vip_content_item" :class="{ greenIcon: user.vip != 1 }">模型下载</div>
				    	 </div>
				     </div>
					  <div class="m-btn black radius buyVipBtn" @click="onShowPayQrcode(orderInfo1)">立即开通</div>
					  <payQrcode class="pay_qrCdeo" v-model="orderInfo1"></payQrcode>
				 </div>
				 <div class="vip_item">
				     <div class="vip_item_box">
				    	 <div class="vip_info">
				    		 <div class="vip_type">年费会员</div>
				    		 <div class="vip_des">订阅停止后积分冻结</div>
				    		 <div class="vip_price">
								 <p>￥1880/年</p>
								 <p class="oldPrice">可下载500个模型/月</p>
							 </div>
							 <!-- <div class="vip_gift">赠送40积分</div> -->
				    	 </div>
						 <div class="vip_discount">
						 	<p>热门推荐</p>
						 	<p>100%</p>
						 </div>
				    	 <div class="vip_content">
				    		 <div class="vip_content_item" :class="{ greenIcon: user.vip != 1 }">页面浏览</div>
							 <div class="vip_content_item" :class="{ greenIcon: user.vip != 1 }">模型下载</div>
							 <div class="vip_content_item" :class="{ greenIcon: user.vip != 1 }">产品商用</div>
				    	 </div>
				     </div>
					 <div class="m-btn black radius buyVipBtn" @click="onShowPayQrcode(orderInfo2)">立即开通</div>
					 <payQrcode class="pay_qrCdeo" v-model="orderInfo2"></payQrcode>
				 </div>
			 </div>
			<div class="vip_list" v-if="vipPointType === 'point'">
				<div class="vip_item">
				    <div class="vip_item_box">
						<img src="@/assets/icon/jifen.png" class="point_icon" v-if="timeKey == 0">
						<img src="@/assets/icon/jifen_black_2.png" class="point_icon" v-else>
						<div class="point_num"> 100积分 </div>
						<div class="point_price"> ￥10 </div>
						 <!-- <div class="vip_discount">
							<p>限时折扣</p>
							<p>-50%</p>
						 </div> -->
				    </div>
					<div class="m-btn black radius" @click="onShowPayQrcode(orderInfo3)">购买</div>
					<payQrcode class="pay_qrCdeo" v-model="orderInfo3"></payQrcode>
				</div>
				<div class="vip_item">
				    <div class="vip_item_box">
						<img src="@/assets/icon/jifen.png" class="point_icon" v-if="timeKey == 0">
						<img src="@/assets/icon/jifen_black_2.png" class="point_icon" v-else>
						<div class="point_num"> 1000积分 </div>
						<div class="point_price"> ￥100 </div>
					 <!-- <div class="vip_discount">
						<p>限时折扣</p>
						<p>-50%</p>
					 </div> -->
				    </div>
					<div class="m-btn black radius" @click="onShowPayQrcode(orderInfo4)">购买</div>
					<payQrcode class="pay_qrCdeo" v-model="orderInfo4"></payQrcode>
				</div>
				<div class="vip_item">
				    <div class="vip_item_box">
						<img src="@/assets/icon/jifen.png" class="point_icon" v-if="timeKey == 0">
						<img src="@/assets/icon/jifen_black_2.png" class="point_icon" v-else>
						<div class="point_num"> 2300积分 </div>
						<div class="point_price"> ￥200 </div>
					 <!-- <div class="vip_discount">
						<p>限时折扣</p>
						<p>-50%</p>
					 </div> -->
				    </div>
					<div class="m-btn black radius" @click="onShowPayQrcode(orderInfo5)">购买</div>
					<payQrcode class="pay_qrCdeo" v-model="orderInfo5"></payQrcode>
				</div>
				<div class="vip_item">
				    <div class="vip_item_box">
						<img src="@/assets/icon/jifen.png" class="point_icon" v-if="timeKey == 0">
						<img src="@/assets/icon/jifen_black_2.png" class="point_icon" v-else>
						<div class="point_num"> 6000积分 </div>
						<div class="point_price"> ￥500 </div>
					<!-- <div class="vip_discount">
						<p>限时折扣</p>
						<p>-50%</p>
					 </div> -->
				    </div>
					<div class="m-btn black radius" @click="onShowPayQrcode(orderInfo6)">购买</div>
					<payQrcode class="pay_qrCdeo" v-model="orderInfo6"></payQrcode>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import payQrcode from'./pay_qrCode_module.vue'
	import { mapMutations, mapState } from 'vuex'
	export default {
		name: 'pointVip',
		components: {
			 payQrcode
		},
		data: () => ({
			visible: true,
			orderInfo1: { product: 'vip', vipType: 1, tradeType: 'NATIVE', show: false, money: 198 },
			orderInfo2: { product: 'vip', vipType: 2, tradeType: 'NATIVE', show: false, money: 1880 },
			orderInfo3: { product: 'point', point: 100, tradeType: 'NATIVE', show: false, money: 10 },
			orderInfo4: { product: 'point', point: 1100, tradeType: 'NATIVE', show: false, money: 100 },
			orderInfo5: { product: 'point', point: 2300, tradeType: 'NATIVE', show: false, money: 200 },
			orderInfo6: { product: 'point', point: 6000, tradeType: 'NATIVE', show: false, money: 500 }
			
		}),
		computed: {
			...mapState('pay', ['vipPointType']),
			
		},
		methods: {
			...mapMutations('pay', ['changeVPVisible']),
			...mapMutations(['targetLoginVisible']),
			onShowPayQrcode(orderInfo) {
				if(!this.user.loginStatus) {
					this.targetLoginVisible(true)
				} else {
					this.orderInfo1.show = false
					this.orderInfo2.show = false
					this.orderInfo3.show = false
					this.orderInfo4.show = false
					this.orderInfo5.show = false
					this.orderInfo6.show = false
					orderInfo.show = true
				}
				
			},
		}
	}
</script>

<style lang="less" scoped>
	.point_vip_main {
		background-color: #fff;
		position: fixed;
		z-index: 1000;
		width: 100vw;
		height: calc( 100vh - 64px);
		top:0;
		left:0;
		margin-top: 64px;
		&.black-theme{
			background-color: @black-theme-bg-color;
			.point_vip_header{
				color: #fff;
			}
			.point_vip_list{
				.vip_list{
					.vip_item{
						background-color: @black-theme-bg-color2;
						.vip_item_box{
							.point_num,.point_price{
								color: #fff;
							}
							.vip_info{
								.vip_des{
									color: #fff;
								}
								.vip_price{
									color: #fff;
									.oldPrice{
										color: #fff;
										background: url(@/assets/icon/jifen_black.png) no-repeat left center;
										background-size: 18px auto;
									}
								}
								
							}
							.vip_content{
								color: #fff;
							}
							.vip_discount{
								&:after{
									background-color: #333;
								}
							}
						}
					}
				}
			}
			
		}
		.point_vip_header{
			display: flex;
			justify-content: center;
			height: 95px;
			position: relative;
			line-height: 95px;
			border-bottom: 2px solid #999;
			.type_item{
				margin: 0 88px;
				cursor: pointer;
				position: relative;
				font-size: 24px;
				&.active:after{
					content: '';
					display: block;
					position: absolute;
					width: 80px;
					height: 4px;
					background: @theme-color;
					bottom: 0;
					left: 50%;
					transform: translate( -50%, 2px );
					border-radius: 4px;
				}
			}
			.close_btn {
				position: absolute;
				right: 30px;
				top: 0px;
				bottom: 0;
				width: 20px;
				height: 20px;
				cursor: pointer;
				margin: auto;
			}
		}
		.point_vip_list{
			padding-top: 80px;
			.vip_list {
				display: flex;
				justify-content: center;
				.vip_item{
					background: #F3F5F7;
					box-shadow: -15px 48px 41px rgba(0, 0, 0, 0.25);
					width: 338px; 
					height: 480px;
					position: relative;
					margin: 0 17px ;
					flex: 0 0 auto;
					.m-btn{
						width: calc(100% - 88px);
						height: 49px;
						line-height: 49px;
						position: absolute;
						bottom: 33px;
						left: 44px;
						font-weight: bold;
						font-size: 24px;
						background: @theme-btn-color;
						&.buyVipBtn{
							font-size: 20px;
						}
					}
					.vip_item_box{
						overflow: hidden;
						padding: 44px 33px;
						position: relative;
						.vip_info{
							
							text-align: center;
							border-bottom: 1px solid #cfcfcf;
							position: relative;
							height: 174px;
							.vip_type{
								font-size: 20px;
								font-weight: bold;
								color:transparent;
								margin-bottom: 12px;
								.theme-color-text()
								
							}
							.vip_des{
								font-size: 14px;
								color: #666;
							}
							.vip_price{
								font-size: 32px;
								font-weight: bold;
								color: #333;
								p{
									margin: 26px 0;
								}
								.oldPrice {
									margin: 0;
									font-size: 14px;
									color: #666;
									text-align: left;
									padding-left: 25px;
									background: url(@/assets/icon/jifen.png) no-repeat left center;
									background-size: 18px auto;
									position: absolute;
									left:0;
									bottom:5px;
								}
							}
							.vip_gift{
								position: absolute;
								bottom: 0;
								left: 0;
								line-height: 32px;
								font-size: 14px;
								color: #666;
							}
						}
						.vip_discount{
							color: #fff;
							font-size: 14px;
							line-height: 24px;
							position: absolute;
							top: 0;
							right: 0;
							padding: 5px 11px;
							p{
								position: relative;
								z-index: 2;
								text-align: right;
								font-weight: bold;
								margin-bottom: 0px;
							}
							&:after{
								content: '';
								display: block;
								background-color: #000;
								width: 160px;
								height: 160px;
								position: absolute;
								top:0;
								right:0;
								z-index: 1;
								transform: rotate(-45deg) translate(111px,0px);
							}
						}
						.vip_content {
							padding-top: 20px;
							.vip_content_item {
								padding-left: 33px;
								line-height: 33px;
								background: url(@/assets/icon/gray_right.png) no-repeat left center;
								background-size: 18px 18px;
								font-size: 2px;
								.greenIcon{
									background: url(@/assets/icon/green_right.png) no-repeat left center;
								}
							}
						}
						.point_icon{
							width: 54.34px;
							height: 47px;
							margin: 0 auto;
							margin-top: 98px;
							margin-bottom: 34px;
							position: relative;
							left: 0;
							right: 0;
							display: block;
						}
						.point_num{
							font-weight: 700;
							font-size: 20px;
							line-height: 24px;
							color: #333;
							margin-bottom: 32px;
							text-align: center;
						}
						.point_price{
							font-weight: 400;
							font-size: 16px;
							line-height: 24px;
							text-align: center;
							color: #333333;
							.oldPrice{
								font-weight: 400;
								font-size: 12px;
								line-height: 24px;
								text-align: center;
								text-decoration-line: line-through;
								color: #999999;
							}
						}
					}
				}
			}
			
			
		}
		.pay_qrCdeo{
			position: absolute;
			bottom: 10px;
			left:0;
			right:0;
			margin: auto;
			z-index: 10;
		}
	}
</style>