import Vue from 'vue'
import { User } from '@/model/user.js'
import { imgDomain } from '@/api/request.js'
import { Order } from '@/model/order/order.js'
import store from '@/store/index.js'
import bus from '@/utils/bus.js'
import Loca from '@/model/location.js'
import Dic from '@/model/dic.js'

class App {
	constructor() {
		this.user = new User()
		this.order = new Order()
		this.Loca = new Loca()
	}
	
	checkLoginStatus() {
		
	}
}

const app = new App()

Vue.prototype.App = app

Vue.mixin({
	created() {
		
	},
	data: () => {
		return {
			user: app.user,
			loca: app.Loca,
			imgDomain,
			Dic
		}
	},
	computed: {
		timeKey() {
			return store.state.timeKey
		},
		themeClass() {
			return this.timeKey === '1' ? { 'black-theme': true }: null
		}
	},
	// computed: {
	// 	user () {
	// 		return app.user 
	// 	} 
	// },
	methods: {
		onLink(params, isReplace) {
			console.log('onLink', params)
			store.commit('header/changeVPVisible',false)
			store.commit('header/changeUploadM',false)
			this.$router.push(params)
		}
	}
})
Vue.prototype.$bus = bus
export default app