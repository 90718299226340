<template>
	<div class="mogine_home_main">
		<!-- <mHeader></mHeader> -->
		<div class="swiper-banner-box">
			<swiper :options="swiperOption" class="banner_swiper" ref="swiper">
				<swiper-slide v-for="(item,index) in swiperOption.list" :key="index">
					<div class="banner_img_box" :class="{ firstBanner: !index }" @click="onTry(item.path)">
						<img :src="item.img" alt="" class="index-banner">
						<div class="banner_text_box">
							<div class="banner_en_text">{{ item.enText || 'Mogine Hub' }}</div>
							<div class="banner_text">{{ item.text || '摩镜视界' }}</div>
							<div class="banner_des">{{ item.des || '成为AI 驱动的XR全息空间计算平台全球引领者' }}</div>
							<div class="banner_btn">{{ item.btnText || '免费开始体验' }}</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<swiperBar :current="swiperIndex" :length="swiperOption.list.length" @change="onSwiperBarChange"></swiperBar>
		</div>
		<div class="content_title">3D IdeaPod 灵感仓 3D案例 </div>
		<div class="mogine_home_content_1">
			<img src="https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_wecome_img_1_1.png" alt="" class="content_img">
			<img src="https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_wecome_img_1_2.png" alt="" class="content_img">
		</div>
		<div class="mogine_home_content_2">
			<div class="home_content_2_item">
				<img src="https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_wecome_img_2_1.png" alt="" class="content_img">
				<div class="home_content_2_item_title">数百万3D创作者共同在线分享优秀作品</div>
				<div class="home_content_2_item_des">一些3D创作者可能专注与角色设计，创作出令人难忘的虚拟角色</div>
			</div>
			<div class="home_content_2_item">
				<img src="https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_wecome_img_2_2.png" alt="" class="content_img">
				<div class="home_content_2_item_title">在Mogine购买和销售 3D 模型</div>
				<div class="home_content_2_item_des">专注于为3D设计师、建模师和创作者提供一个便捷的市场</div>
			</div>
			<div class="home_content_2_item">
				<img src="https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_wecome_img_2_3.png" alt="" class="content_img">
				<div class="home_content_2_item_title">随时点赞收藏喜欢的内容</div>
				<div class="home_content_2_item_des">
					<p>用户可以随时点赞和收藏自己喜欢的3D作品，</p>
					<p>而这种互动社区参与为用户带来更多乐趣和便利</p>
				</div>
			</div>
		</div>
		
		<div class="content_title">#与Mogine一起创作</div>
		<div class="swiper_box">
			<swiper :options="swiper2Option" class="create_swiper" ref="swiper2">
						<swiper-slide v-for="item in creaters" :key="item.userName">
							<div class="create_box">
								<img class="create_img" :src="item.img" alt="">
								<div class="create_text_box">
									<div class="create_text">{{ item.text }}</div>
									<div class="create_user_info">
										<img class="create_user_img" :src="item.headImage" alt="">
										<div class="create_user_info_box">
											<div class="create_user_name">@{{ item.userName }}</div>
											<div class="user_des">{{ item.des1 }}</div>
											<div class="user_des">{{ item.des2 }}</div>
										</div>
									</div>
								</div>
							</div>
						</swiper-slide>
					</swiper>
					<div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
					<div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
		</div>
		<div class="creater_box">
			<div class="creater_item" v-for="item in creaters" :key="item.userName">
				<div class="creater_info"> 
					<div class="user_info">
						<img :src="item.headImage" alt="" class="headImage">
						<div class="userName">@{{ item.userName }}</div>
					</div>
					<div class="creater_des">
						<p>{{ item.des1 }}</p>
						<p>{{ item.des2 }}</p>
					</div>
				</div>
				<img :src="item.img" alt="" class="creater_img">
				<div class="creater_text_box" :class="{ active: item.showText }" @click="item.showText = !item.showText">
					<div class="creater_text" >{{item.text}}</div>
				</div>
				
			</div>
		</div>
		<div class="pc_box">
			<div class="content_title">Mogine 3D（M3）元宇宙超写实3D数字内容实时创作工具</div>
			<div class="content_des">智能搜索与协同创作，高效实时创作工具</div>
			<div class="content_btn" @click="onLink('/download')">免费开始体验</div>
			<video src="https://mogine.oss-cn-shanghai.aliyuncs.com/upload/gsdemo/mofine_aigc.mp4" class="content_img content_video" controls></video>
			<!-- <img src="https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_wecome_img_3.png" alt="" class="content_img" style="width:1441px;"> -->
		</div>
		<friends></friends>
		<mfooter></mfooter>
	</div>
</template>

<script>
	import friends from '@/components/index/friends.vue'
	import mfooter from '@/components/footer.vue'
	import swiperBar from '@/components/swiper/swiper_bar.vue'
	const createText1 = '“大家好，我是3ru。得益于Mogine团队优秀的资产库和渲染引擎的高效表现，我用1个月时间创作了这个包含40多个镜头的视频，它的主题是记忆。在初次见到这些被扫描还原的老旧电视、杂志、收音机等物件的时候给我留下了很深的印象，如果你在80、90年代出生的话，应该会有同样的感觉。\n为此我萌生了想法，在三维中去重新展示这些过往或者今日不被注意的生活中场景，所以有了这个作品。在这里要特别感谢Mogine团队的工作，用现代的数字流程使我们能够再次清晰自由地看到这些可能被遗忘的东西。”'
	const createText2 = '“很开心参与这次momentor的毕业设计 很感谢Mogine团队提供的的优秀资产库 让我更好的完成了我的作品 因为非常喜欢水木茂老师的鬼太郎漫画 所以就想用自己的风格去展示成三维的作品 非常幸运地遇到Mogine资产库 提供了非常多高质量的资产 让我这次的作品更好的展现出来 希望Mogine越来越好 我也会继续用下去不断地创作的~”'
	const createText3 = '“当生活的繁琐、工作的压力占据了你的心，你是否需要寻找一片心灵的净土？\n当我们走1了很久，可否停下脚步，去感受自然的美好？于是，这个项目便诞生了-古风空镜\n那日，鸟语花香，清风徐徐；\n那里，长流细水，蛙响蝉鸣；\n我希望你，享受这份宁静。\n为了增强真实性及沉浸感，短片采用Mogine资产库配合UE5引擎进行制作。\n为了平衡超写实和写意风格，选择了极繁（自然）到极简（人文元素）的过渡。\n环境的繁与简、运镜的动与静、天气的阳与阴，都是希望，你能找到身边最美的风景。\n最后，祝Mogine团队一切顺利，为每一位创作者提供更加精良的服务”'
	
	export default {
		name: 'home',
		components: { mfooter, friends, swiperBar },
		data: function () {
			return {
				swiperOption: {
					loop: true,
					speed: 2000,
					allowTouchMove: false,
					autoplay: true,
					on: {
						slideChangeTransitionStart: (params) => {	
							if(this.$refs.swiper.swiper){
								this.swiperIndex = this.$refs.swiper.swiper.realIndex
							}
						}
					},
					list: [
						{
							img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mofine_index_3DWorld.png',  
							enText: 'Mogine Lab',
							text: '摩镜视界',
							des: '捕获每一个3D精彩瞬间，创建每一个令人惊叹的3D魔幻视界',
							btnText: '免费开始体验',
							path: '/lab/index'
						},
						{ img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_index_banner_2.png' },
						{ img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_index_banner_0.jpg' },
						// { img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_index_banner_3.png' },
						// { img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_index_banner_4.png' },
						{ img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_index_banner_5.png' },
						
					],
				},
				swiperIndex: 0,
				swiper2Option: {
					loop: true,
					navigation: {
					      nextEl: '.swiper-button-next',
					      prevEl: '.swiper-button-prev',
					    },
				},
				creaters: [
					{ 
						img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_create_img.png', 	
						text: createText1, 
						userName: '3ru', 
						headImage: require('@/assets/wecome/user_header/user_header.png'),
						des1: '环境艺术家', 
						des2: 'Mogine渲染挑战大赛冠军' ,
						showText: false
					},
					{ 
						img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_create_img_1.png', 	
						text: createText2, 
						userName: 'zzcircle', 
						headImage: require('@/assets/wecome/user_header/user_header_1.png'),
						des1: '插画师', 
						des2: 'Momenter学院' ,
						showText: false
					},
					{ 
						img: 'https://mogine.oss-cn-shanghai.aliyuncs.com/direct/mogine_create_img_2.png', 	
						text: createText3, 
						userName: '风起时', 	
						headImage: require('@/assets/wecome/user_header/user_header_2.png'),
						des1: '独立游戏制作人', 
						des2: 'Mogine渲染挑战大赛冠军',
						showText: false
					},
					
				]
			}
		},
		created() {
			console.log(this)
		},
		methods: {
			onTry(url) {
				if (url == '/lab/index') {
					this.$router.push(url || '/download')
				} else {
					window.location.href = 'Mogine://';
					setTimeout(() => {
					    if (!document.hasFocus()) {
					        // 如果窗口失去焦点，说明应用已经打开
					        return;
					    }
					    // 如果代码执行到这里，说明应用可能没有打开
					    // var userResponse = confirm("It seems the Mogine app is not installed on your computer. Would you like to download it?");
						// this.$message.default("敬请期待")
						this.$router.push(url || '/download')
					    // if (userResponse) {
					    //     // 这里可以替换为应用的下载链接
					    //     window.location.href = 'https://download-link-for-mogine-app.com';
					    // }
					}, 500);
				}
				
			},
			onSwiperBarChange(index) {
				this.$refs.swiper.swiper.slideTo(index)
			}
		}
	}
</script>

<style scoped lang="less">
	.mogine_home_main{
		background-color: #000;
		padding-top: 80px;
		.swiper-banner-box{
			position: relative;
			z-index: 100;
		}
		.banner_swiper{
			width: 100%;
			height: 7.55rem;
			position: relative;
			.banner_img_box{
				width:100%;
				height: 7.55rem;
				position: relative;
				cursor: pointer;
				&.firstBanner{
					.theme3-border(8px)
				}
				.banner_text_box{
					position: absolute;
					bottom: 79px;
					left: 96px;
					color: #fff;
					z-index: 1;
					.banner_en_text{
						font-size: 40px;
						font-weight: bold;
						line-height: 56px;
					}
					.banner_text{
						font-size: 60px;
						font-weight: 900;
						line-height: 72px;
						margin-bottom: 23px;
					}
					.banner_des{
						font-size: 32px;
						font-weight: 400;
						line-height: 40px;
						margin-bottom: 57px;
					}
					.banner_btn{
						width: 318px;
						height: 80px;
						line-height: 80px;
						color: #fff;
						text-align: center;
						font-size: 24px;
						border-radius: 40px;
						background: @theme3-color;
						cursor: pointer;
					}
				}
				
			}
			.index-banner{
				width:100%;
				height: 8.35rem;
				object-fit: cover;
			}
			
		}
		.content_title{
			text-align: center;
			font-size: 40px;
			color: #fff;
			margin-top: 80px;
			margin-bottom: 60px;
		}
		.content_img{
			width: 100%;
			display: block;
			margin: 0 auto;
			max-width: 100%;
		}
		.content_video{
			width: 1440px;
			max-width: 80%;
		}
		.swiper_box{
			width: 1224px;
			margin: 0 auto;
			position: relative;
			.swiper-button-prev{
				left: -48px;
				background-image: url(@/assets/wecome/swiper_prev.png);
				background-size: 32px;
			}
			.swiper-button-next{
				right: -48px;
				background-image: url(@/assets/wecome/swiper_next.png);
				background-size: 32px;
			}
		}
		.creater_box{
			display: none;
		}
		.create_swiper{
			width: 100%;
			.create_box{
				width: 1224px;
				display: flex;
				.create_img{
					width: 916px;
					height: 515px;
				}
				.create_text_box{
					width: 308px;
					height: 515px;
					padding: 32px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.create_text{
						font-size: 12px;
						line-height:18px;
						color: #ABAEB4;
						white-space: pre-wrap;
					}
					.create_user_info{
						display: flex;
						.create_user_img{
							width: 50px;
							height:50px;
							border-radius: 25px;
							margin-right: 16px;
						}
						.create_user_info_box{
							.create_user_name{
								font-size: 14px;
								line-height: 20px;
								color: #e1e1e2;
							}
							.user_des{
								font-size: 12px;
								color: #687382;
							}
						}
					}
				}
				
			}
		}
		.content_des{
			font-size: 20px;
			line-height:28px;
			margin-bottom: 55px;
			color: #fff;
			text-align:  center;
		}
		.content_btn{
			width: 318px;
			height: 80px;
			line-height: 80px;
			text-align: center;
			font-size: 24px;
			background: @theme3-color;
			border-radius: 40px;
			margin: 0 auto;
			color: #fff;
			margin-bottom: 55px;
			cursor: pointer;	
		}
		.friend_list{
			padding-top: 200px;
			text-align: center;
			color: #fff;
			.title{
				font-size: 40px;
				line-height: 56px;
				margin-bottom: 80px;
			}
			.friend_logos{
				width: 1019px;
				display: flex;
				justify-content: space-between;
				margin: 0 auto;
				.logo_img{
					width: 199px;
					height: 199px;
				}
			}
		}
		.mogine_home_content_1{
			.content_img{
				width: 50%;
				display: inline-block;
			}
		}
		.mogine_home_content_2{
			display: flex;
			justify-content: space-between;
			padding: 0 .42rem;
			.home_content_2_item{
				width: 5.17rem;
				// height: 5.81rem;
				background: rgba(14, 15, 16, 1);
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 55px;
				color: #fff;
				padding-bottom: 40px;
				.content_img{
					height: 265px;
					margin-bottom: 89px;
					width: auto;
				}
				.home_content_2_item_title{
					font-family: PingFang SC;
					font-size: 32px;
					font-weight: 700;
					text-align: center;
					margin-bottom: 32px;
				}
				.home_content_2_item_des{
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					color: rgba(119, 124, 132, 1);

				}
				&:first-child {
					width: 7.73rem;
				}
			}
		}
	}
</style>

<style scoped lang="less">
	@media screen and (max-width: 760px) {
		.mogine_home_main{
			.swiper-banner-box{
				.banner_swiper{
					.banner_img_box{
						&.firstBanner{
							.theme3-border(2px)
						}
						.banner_text_box{
							left: 20px;
							bottom: 5px;
							.banner_en_text{
								font-size: 20px;
								transform: scale(.5) translateX(-50%);
								line-height: 10px;
								margin-bottom: 2px;
							}
							.banner_text{
								font-size: 15px;
								line-height: 15px;
								margin-bottom: 5px;
							}
							.banner_des{
								font-size:8px;
								line-height: 8px;
								// transform: scale(.5) translateX(-50%);
								margin-bottom: 0;
							}
							.banner_btn{
								display: none;
							}
						}
					}
				}
				.slide_bar{
					bottom: 9px;
					right: 17px;
					.slide-bar-item{
						width: 2px;
						height: 2px;
						margin-left: 4px;
						&:first-child{
							width: 10px;
							height: 2px;
						}
					}
					
				}
			}
			.content_title{
				font-size: 28px;
				font-weight: 400;
				line-height: 39px;
				margin-top: 36px;
				margin-bottom: 28px;
			}
			.mogine_home_content_1{
				margin-bottom: 50px;
				.content_img{
					width: 100%;
					display: block;
					margin-bottom: 28px;
				}
			}
			.mogine_home_content_2{
				display: block;
				.home_content_2_item{
					width: 100%;
					height: auto;
					padding-top: 31px;
					margin-bottom: 8px;
					padding-bottom: 50px;
					.content_img{
						width: 283px;
						height: 167px;
						object-fit: cover;
						margin-bottom: 51px;
						border-radius: 7px;
					}
					.home_content_2_item_title{
						font-size: 18px;
						margin-bottom: 21px;
					}
					.home_content_2_item_des{
						font-size: 16px;
						transform: scale(.5);
						white-space: nowrap;
						text-align: center;
					}
					&:first-child {
						width: 100%;
						.content_img{
							width: 330px;
							height: 152px;
						}
					}
				}
			}
			.swiper_box{
				display: none;
			}
			.creater_box{
				display: block;
				.creater_item{
					background: rgba(14, 15, 16, 1);
					color: #fff;
					margin-bottom: 20px;
					.creater_info{
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 16px;
						.user_info{
							display: flex;
							align-items: center;
							.headImage{
								width: 56px;
								height: 56px;
								border-radius: 50%;
								margin-right: 16px;
							}
							.userName{
								font-size: 20px;
								line-height: 28px;
								text-align: left;
								font-size: 16px;
							}
						}
						
						.creater_des{
							font-size: 12px;
							line-height: 17px;
							letter-spacing: 0.01em;
							text-align: right;
							color: rgba(104, 115, 130, 1);
						}
					}
					.creater_img{
						width: 100%;
					}
					.creater_text_box{
						padding-bottom: 30px;
						position: relative;
						&:after{
							width: 12px;
							height: 12px;
							content: '';
							display: block;
							position: absolute;
							bottom: 4px;
							left: 50%;
							background: url(@/assets/index/down.png) no-repeat center center;
							background-size: 12px;
							transform: translateX(-50%);
							z-index: 1;
							transition: all 1s;
						}
						&.active{
							.creater_text{
								max-height: 300px;
							}
							&:after{
								transform:translate(-50%) rotate(180deg);
							}
							
						}
					}
					.creater_text{
						padding: 24px;
						font-size: 12px;
						line-height: 18px;
						color: rgba(171,174,180,1);
						overflow: hidden;
						max-height: 96px;
						transition: all 1s;
						padding-bottom: 0;
					}
				}
			}
			.pc_box{
				display: none;
			}
			.friend_list{
				 padding: 0 17px;
				 padding-top: 52px;
				 width: 100%;
				 margin-bottom: 73px;
				 .title{
					 font-size: 28px;
					 margin-bottom: 40px;
				 }
				 .friend_logos{
					 width: 100%;
					 display: grid;
					 grid-template-columns: repeat(3, 1fr);
					 grid-gap: 8px;
					 position: relative;
					 .logo_img_box{
						width: auto;
					 }
					 .logo_img{
						 width: 100%;
						 height: auto;
						 margin-bottom: 8px;
					 }
				 }
			}
		}
	}
</style>