export default {
  namespaced: true, 
  state: {
	  vipPointType: false,
	  uploadModelVisible: false,
	  homeScrollTop: true
  },
  getters: {
  },
  mutations: {
	  changeVPVisible(state,type) {
		  state.vipPointType = type
	  },
	  changeUploadM(state, visible) {
		  state.uploadModelVisible = visible
	  },
	  changeScrollTop(state, status) {
		  state.homeScrollTop = status
	  }
  },
  actions: {
  },
  modules: {
  }
}