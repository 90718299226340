import { axios, service, $ajax, $xhr } from './request'

let apis = {}

const URLS = [
	
	{ name: 'sendCode', url: '/api/login/sendCode', method: 'post' },
	{ name: 'loginSubmit', url: '/api/login/submit', method: 'post' },
	{ name: 'qrCodeConnect', url: '/api/login/qr_connect', method: 'post' },
	{ name: 'getCaptcha', url: '/api/login/captcha', method: 'get' },
	
	{ name: 'resourceTypeList', url: '/api/resource/type/list', method: 'get' },
	{ name: 'resourceList', url: '/api/resource/list', method: 'get' },
	{ name: 'listByImage', url: '/api/resource/listByImage', method: 'post', contentType: 'multipart/form-data' },
	
	
	{ name: 'clientInfo', url: '/api/client/info', method: 'get' },
	{ name: 'clientPointLog', url: '/api/client/pointLog', method: 'get' },
	{ name: 'clientUpdate', url: '/api/client/update', method: 'post' },
	
	{ name: 'clientMessageList', url: '/api/client/message/list', method: 'get' },
	{ name: 'clientMessageUnread', url: '/api/client/message/unread', method: 'get' },
	{ name: 'clientMessageAllread', url: '/api/client/message/all/read', method: 'get' },
	{ name: 'clientMessagedel', url: '/api/client/message/del', method: 'get' },
	
	{ name: 'putFile', url: '/api/common/put-file', method: 'post',  contentType: 'multipart/form-data'},
	
	{ name: 'favoriteList', url: '/api/favorite/list', method: 'get' },
	{ name: 'favoriteRecommend', url: '/api/favorite/recommend', method: 'get' },
	{ name: 'favoriteSubmit', url: '/api/favorite/submit', method: 'post' },
	{ name: 'favoriteRemove', url: '/api/favorite/remove', method: 'post' },
	{ name: 'favoriteItemSave', url: '/api/favorite/item/save', method: 'post' },
	{ name: 'favoriteLItemist', url: '/api/favorite/item/list', method: 'get' },
	{ name: 'favoriteItemRemove', url: '/api/favorite/item/remove', method: 'post' },
	
	
	{ name: 'orderWechatCreateOrder', url: '/api/order/wechat/createOrder', method: 'post' },
	{ name: 'orderWechatSelect', url: '/api/order/wechat/select', method: 'post' },
	{ name: 'orderPayment', url: '/api/order/payment', method: 'post' },
	
	{ name: 'addBuyCart', url: '/api/order/buyCar/add', method: 'post' },
	{ name: 'delBuyCart', url: '/api/order/buyCar/del', method: 'post' },
	{ name: 'BuyCartList', url: '/api/order/buyCar/list', method: 'get' },
	{ name: 'downloadQiniu', url: '/api/client/download/qiniu', method: 'get', responseType: 'arraybuffer' },
	{ name: 'downloadAli', url: '/api/client/download/ali', method: 'get', responseType: 'arraybuffer', contentType:'application/x-www-form-urlencoded;' },
	{ name: 'dic', url: '/blade-system/dict/dictionary', method: 'get' },
	{ name: 'contactUs', url: '/api/common/public/contact/us', method: 'post' }
	
]

URLS.forEach(api => {
	let { name, url, method, contentType, responseType } = api;
	let headers = { };
	if(contentType) {
		headers['Content-Type'] = contentType
	}
	apis[name] = function (data) {
		let requestParams = { url: url, method, headers}
		if (responseType) requestParams.responseType = responseType
		if (contentType) {
			if(method === 'post')requestParams.data = data
			if(method === 'get')requestParams.params = data
		} else {
			requestParams.data = data
			requestParams.params = data
		}
		
		return axios(requestParams)
	}
})
 
 
 apis.downloadAli = function ( data, onProgress	) {
	let item = URLS.find(item => item.name === 'downloadAli')
	let { name, url, method, contentType, responseType } = item;
	let header = { };
	let requestParams = { url: url, method, header, onDownloadProgress: onProgress}
	if (responseType) requestParams.responseType = responseType
	requestParams.xhrFields = { responseType }
	if(method === 'post')requestParams.data = data
	if(method === 'get')requestParams.params = data
	 return axios(requestParams)
 }
 
 apis.refreshToken = function (refreshToken) {
	 let formData = new FormData();
	 formData.append('grantType', 'refresh_token')
	 formData.append('refreshToken', refreshToken);
	 //console.log(formData);
	 return axios({
	   url: '/api/login/submit',
	   method: 'post',
	   headers: {
	     'Content-Type': 'multipart/form-data',
	     'Authorization':'Basic YXBpOmFwaV9zZWNyZXQ='
	   },
	   data: formData
	 })
 }

export default apis

var settings = {
  "url": "http://192.168.1.128:82/api/client/download/ali?key=direct%2F1680256256376_890i33jjjn.zip",
  "method": "GET",
  "timeout": 0,
  "headers": {
    "Authorization": "Basic YXBpOmFwaV9zZWNyZXQ=",
    "Blade-Auth": "bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ1c2VyX2lkIjoiMjEiLCJ0b2tlbl90eXBlIjoiYWNjZXNzX3Rva2VuIiwiY2xpZW50X2lkIjoiYXBpIiwiZXhwIjoxNjgwODYwNzQyLCJuYmYiOjE2ODAyNTU5NDJ9.XBaFKqlWnIi0J07nQYtJG0rfQuzHeuBzPRTmBpuztdp-HUN3eIkZi159mG7JhTo9TeoOXhBDYujLfQEwzC7i9g"
  },
};